import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Story of my life. Dedicated to a person that I admire so much: my father.`}</p>
    <p>{`Today would have been 70 years old and it almost 4 years that I am missing you.
So much it happened in these 4 years without you. `}</p>
    <p>{`I would like to tell you everything in person but it is not possible.`}</p>
    <h2>{`You have been a role model for me.`}</h2>
    <p>{`I loved your way of caring for your family and everyone else that was in your life.`}</p>
    <p>{`Your work ethic.`}</p>
    <p>{`Your way to find always a solution.`}</p>
    <p>{`Your stubbornness.`}</p>
    <p>{`Your smiles.`}</p>
    <p>{`Your way to make everyone laugh.`}</p>
    <p>{`Your cooking.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/700/1*9mN48ux0APqXp-SZ6ayH4g.png",
        "alt": "Father"
      }}></img></p>
    <p>{`Always happy. Always with a big heart.`}</p>
    <p>{`You were strong. Really strong in your mind.`}</p>
    <p>{`A strength that let you win all the difficulties in your life.
A strength that I admire and I wish to have…
A strength that I will bring with me in my personal and professional life.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/700/1*OdJkUCmY5jAhrBO6Elvy5Q.jpeg",
        "alt": "FatherMe"
      }}></img></p>
    <b>Thank you for the father you have been with me!</b>
    <p>{`I hope to be good as you some day.
In this special day, happy birthday my dear father.`}</p>
    <p>{`Wherever you are.
Whoever you are with.`}</p>
    <blockquote>
      <p parentName="blockquote">{`You will be always with me dad!
You will be always in my mind and heart.
The memories of our beautiful family will always be with me.`}</p>
    </blockquote>
    <h4>{`AUTHOR`}</h4>
    <p>{`Domenico is a software developer with a passion for design, psychology, and leadership.
If you need help with software development, you need consultancy for your technical challenges or you need a leader for your software team or just want to collaborate online, feel free to contact me!`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      